.selected-item {
  text-align: center;
}

.controls-container {
  --highlight-width: auto;
  --highlight-x-pos: 0;

  display: flex;

  margin: 0;
  font-family: 'SF Compact';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.controls {
  display: inline-flex;
  justify-content: space-between;
  border-radius: 4px;
  border: 1px solid rgba(99, 96, 255, 0.1);
  border-radius: 4px;
  max-width: 500px;
  padding: 12px;
  margin: auto;
  overflow: hidden;
  position: relative;
}

.controls input {
  opacity: 0;
  margin: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute;
  width: 100%;
  cursor: pointer;
  height: 100%;
}

.segment {
  /* width: 100%; uncomment for each segment to have matching width */
  min-width: 100px;
  position: relative;
  text-align: center;
  z-index: 1;
}

.segment label {
  cursor: pointer;
  display: block;
  font-weight: 400;
  font-size: inherit;
  padding: 8px 12px;
  transition: color 0.5s ease;

  @media screen and (max-width: 450px) {
    font-size: 18px;
  }
}

.segment.active label {
  color: #fff;
}

.controls::before {
  content: '';
  background: #5465ff;
  border-radius: 4px;
  width: calc(var(--highlight-width));
  transform: translateX(calc(var(--highlight-x-pos)));
  position: absolute;
  top: 2px;
  bottom: 2px;
  left: 0;
  z-index: 0;
}

/* Only allow transitions once component is ready */
.controls.ready::before {
  transition:
    transform 0.3s ease,
    width 0.3s ease;
}
